import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MailChimpForm from "../components/Mailchimpform"

const IndexPage = () => (
  <Layout pageName="Blog" pageLink="/blog/">
    <SEO
      titleTag="TheTechOasis, the newsletter that simplifies AI & Crypto"
      description="Landing page of The Tech Oasis newsletter about AI & Crypto in a digested manner so that even non-tech readers can understand."
    />
    <div
      style={{
        paddingLeft: "10%",
        paddingRight: "10%",
        textAlign: "center",
        paddingTop: "12%",
        paddingBottom: "200px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          marginBottom: "10px",
          color: "black",
          fontFamily: "Arial",
          fontSize: "60px",
        }}
      >
        Become <span style={{ color: "#FF0063" }}>aware</span>. Be{" "}
        <span style={{ color: "#FF0063" }}>ready</span>.
      </h1>
      <h4 style={{ fontSize: "1.1rem" }}>
        Join the community of leaders that stay easily up-to-date with the
        essential AI & Crypto insights and news simplified so you can
        supercharge your learning with no effort.
      </h4>
      <MailChimpForm />
    </div>
  </Layout>
)

export default IndexPage
